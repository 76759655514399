<template>
  <div class="container-fluid">
    <div>
      <!-- <div class="slider slider-animation bv xz">
        <div class="row">
          <div class="col-lg-12">
            <carousel
              v-if="sub_category_sliders"
              :items="1"
              :nav="false"
              :autoplay="true"
              :autoplayTimeout="2000"
            >
              <a
                v-for="slider in sub_category_sliders"
                :href="slider.url"
                :key="slider.id"
              >
                <img :src="img_base_url + slider.image" />
              </a>
            </carousel>
          </div>
        </div>
      </div> -->

      <!-- Breadcrumb Start-->
      <ul class="breadcrumb breadcrumb_style">
        <li>
          <router-link
            :to="{
              name: 'category',
              params: {
                slug: $route.params.category,
              },
            }"
          >
            <i class="bi bi-house"></i>
            {{ $route.params.category }} </router-link
          >/
          <router-link
            :to="{
              name: 'sub_category',
              params: {
                category: $route.params.category,
                slug: subCategory.slug,
              },
            }"
          >
            {{ subCategory.name }}
          </router-link>
        </li>
      </ul>

      <!-- Breadcrumb End-->
      <div class="row">
        <!--Left Part Start -->

        <div id="content" class="col-lg-12 col-md-12 col-xs-12">
          <!-- start sub category with filter -->
          <div class="row sub_c_row">
            <div
              class="text-center sub_category_options"
              v-for="(sub_c, s_index) in subCategory.sub_sub_category"
              :key="s_index"
            >
              <router-link
                class="btn btn-danger rounded"
                :to="{
                  name: 'sub_sub_category',
                  params: {
                    category: $route.params.category,
                    sub_category: subCategory.slug,
                    slug: sub_c.slug,
                  },
                }"
              >
                <span class="spinner-grow spinner-grow-sm"></span>
                {{ sub_c.name }}
              </router-link>
            </div>
          </div>
          <!-- end sub category with filter -->
          <!-- filter & sort by -->
          <div class="filter_sort_container">
            <div class="row" style="align-items: end;">
              <div class="price_inputs">
                <div class="input_price_box">
                  <input
                    class="form-control number_appearance"
                    v-model.number="price_filter.min_price"
                    placeholder="Minimum Price"
                    min="1"
                    type="number"
                  />
                </div>
                <div class="input_price_box">
  
                  <input
                    class="form-control number_appearance"
                    v-model.number="price_filter.max_price"
                    placeholder="Maximum Price"
                    type="number"
                  />
                </div>
              </div>

              <div class="input_price_box">
                <button
                  @click.prevent="priceFilter"
                  class="btn btn-block btn_search btn-danger"
                >
                  Filter
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-funnel"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div class="sort-box d-flex sort_box">
              <p style="white-space:nowrap">Sort By</p>
              <select
                v-model="sort_by_price"
                @change="sortByChange"
                class="form-control number_appearance"
              >
                <option value="select_by" disabled
                  >Default</option
                >
                <option value="1">Low to High Price</option>
                <option value="2">High to Low Price</option>
              </select>
            </div>
          </div>
          <Products v-if="products.length > 0" :products="products" />
          <!-- <Products
            v-if=" products.length > 0"
            :products="products"
          /> -->
          <NoProductFound v-else />
        </div>
      </div>
    </div>
    <InfiniteLoading @distance="0.5" @infinite="categoryProducts">
      <div slot="no-more"></div>
    </InfiniteLoading>
  </div>
</template>
<script>
// @ is an alias to /src
import InfiniteLoading from "vue-infinite-loading";
// import carousel from "vue-owl-carousel";
import Products from "../components/Products.vue";
import NoProductFound from "./NoProductFound";

export default {
  name: "sub_category",
  created() {
    this.$store.dispatch("subCategory", this.$route.params.slug);
  },
  components: {
    InfiniteLoading,
    // carousel,
    Products,
    NoProductFound,
  },
  data() {
    return {
      menuLimit: 5,
      showMenus: false,
      showAllMenusButton: true,
      page: 1,
      products: [],
      category_name: "",
      sub_category_sliders: "",
      price_page: 1,
      price_filter: {
        min_price: '',
        max_price: '',
      },
      sort_by_price: "select_by",
      quick_v_product_id: "",
      o_modal: false,
    };
  },

  methods: {
    showAllMenusFunctionalities() {
      this.showAllMenusButton = false;
      this.showMenus = true;
    },
    // sub_category_slider() {
    //   this.axios
    //     .get("display/sub/category/slider", {
    //       params: {
    //         slug: this.$route.params.slug,
    //       },
    //     })
    //     .then((resp) => {
    //       this.sub_category_sliders = resp.data.sub_category_sliders;
    //     });
    // },

    categoryProducts($state) {
      this.axios
        .get(
          "sub-category-wise-products/" +
            this.$route.params.slug +
            "?page=" +
            this.page,
          {
            headers: this.$apiHeader,
          }
        )
        .then((resp) => {
          // console.log(resp)
          if (resp.data.success == true && resp.data.products.data.length) {
            this.products.push(...resp.data.products.data);
            this.page += 1;
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
        });
    },

    // Product Price Sorting Start
    sortByChange() {
      let value = this.sort_by_price;
      if (value == 1) {
        this.sortLowest();
      } else {
        this.sortHighest();
      }
    },
    sortLowest() {
      this.products.sort((a, b) => {
        return a.price - b.price;
      });
    },
    sortHighest() {
      this.products.sort((a, b) => {
        return b.price - a.price;
      });
    },

    priceFilter() {
      this.$Progress.start();
      let max_price = this.price_filter.max_price;
      let min_price = this.price_filter.min_price;
      let products = [];
      this.products.forEach((product) => {
        if (product.price >= min_price && product.price <= max_price) {
          products.push(product);
        }
      });
      if (products.length > 0) {
        this.products = products;
      } else {
        this.$swal({
          type: "warning",
          text: " ): no product found......",
          duration: 3000,
        });
      }
      this.$Progress.finish();
    },
  },
  computed: {
    subCategory() {
      return this.$store.state.subCategory;
    },
    category_sliders() {
      return this.$store.getters.category_sliders;
    },
  },
};
</script>
